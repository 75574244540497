import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import CheckoutComponent from '../molecules/CheckoutComponent'

export default function Checkout() {
  return (
    <Layout disableCart>
      <SEO title='Checkout' />
      <CheckoutComponent />
    </Layout>
  )
}
